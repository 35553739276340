import React from "react";
import styled from "styled-components";
import { Main, BroadcastStatusBar, RequestBookingBanner } from "./components";
import { Helmet } from "react-helmet";

const Hero = styled.div`
  width: 100%;
  height: 100px;
  margin-bottom: 25px;
  background: linear-gradient(to bottom left, #2e3f67, #0d1938);
  /* background-color: #2e3f67; */
  color: #ffffff;
  text-align: center;
  height: 100px;
  line-height: 100px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 2.5em;
  overflow: hidden;

  h1 {
    background: linear-gradient(to bottom, #ffffff 0%, #ffffff 30%, #aaaaaa);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0px;
    font-size: inherit;
  }

  @media (max-width: 500px) {
    font-size: 2em;
    height: 75px;
    line-height: 75px;
    margin-bottom: 10px;
  }
`;

const TextPane = styled.article`
  padding: 25px;

  a {
    color: #333;

    &[name] {
      text-decoration: none;
      display: block;

      &:not(:first-child) {
        margin-top: 50px;
      }
    }

    h2 {
      position: relative;
      &::before {
        position: absolute;
        left: -1.5em;
        padding-right: 1.5em;
        line-height: 1.6em;
        font-size: 0.7em;
        font-family: "Font Awesome 5 Pro";
        content: "\uf0c1";
        color: #bbb;
        opacity: 0;
        user-select: none;
        cursor: normal;
      }

      &:hover::before {
        opacity: 1;
      }
    }

    @media (prefers-color-scheme: dark) {
      color: #ddd;
    }
  }

  h2 {
    text-transform: uppercase;
    margin-top: 50px;

    &:first-child {
      margin-top: 0px;
    }

    @media (prefers-color-scheme: dark) {
      color: #fff;
    }
  }

  h3 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0px;
    margin-bottom: 5px;
    font-style: italic;
    color: #555;

    & + p {
      margin-top: 5px;
      margin-bottom: 0px;
    }
  }

  i {
    margin-right: 5px;
    /* color: #555; */

    /* @media (prefers-color-scheme: dark) { */
    color: #fff;
    /* } */
  }

  p,
  li {
    line-height: 1.5em;
  }

  @media (prefers-color-scheme: dark) {
    color: #fff;
  }
`;

const VisitShop = styled.a`
  &.refer {
    margin-top: 25px;
    display: block;
    text-align: center;
    height: 40px;
    padding: 8px;
    box-sizing: border-box;
    line-height: 22px;
    font-size: 1em;
    border: none;
    background-color: #0082ff;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 0.75;
    }

    i {
      margin-left: 10px;
    }
  }
`;

function Shop() {
  return (
    <Main>
      <Helmet>
        <title>Sim Broadcasts | Shop</title>
      </Helmet>
      <BroadcastStatusBar />
      <Hero>
        <h1>Shop</h1>
      </Hero>
      <TextPane>
        <a name="mission" href="#mission">
          <h2>Sim Broadcasts Merchandise</h2>
        </a>
        <p>
          We've teamed up with{" "}
          <a
            href="https://evertaitgraphics.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Evertait Graphics
          </a>{" "}
          to provide a range of merchandise for Sim Broadcasts and{" "}
          <a
            href="https://newdimensionracing.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            New Dimension Racing
          </a>{" "}
          supporters! We're currently offering a range of T-Shirts and sticker
          sheets.
        </p>
        <VisitShop
          className="refer"
          href="https://evertaitgraphics.com/collections/sbtv-ndr"
        >
          Visit our shop on Evertait Graphics{" "}
          <i className="fas fa-external-link"></i>
        </VisitShop>
      </TextPane>
      <RequestBookingBanner />
    </Main>
  );
}

export default Shop;
