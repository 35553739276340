import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Wrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;

  h1 {
    @media (prefers-color-scheme: dark) {
      color: #fff;
    }
  }

  a {
    color: #333;

    @media (prefers-color-scheme: dark) {
      color: #ddd;
    }
  }

  @media (prefers-color-scheme: dark) {
    color: #fff;
  }
`;

function Error() {
  return (
    <Wrapper>
      <Helmet>
        <title>Sim Broadcasts | Page Not Found</title>
      </Helmet>
      <h1>404</h1>
      <p>
        Page Not Found - <Link to="/">Return Home</Link>
      </p>
    </Wrapper>
  );
}

export default Error;
