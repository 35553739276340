import React from "react";
import styled from "styled-components";
import logo from "../assets/logo/sim-broadcasts-logo-t-w.png";
import { Helmet } from "react-helmet";

const HoldingPage = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: #181c25;
`;

const HoldingMessage = styled.div`
  margin: auto;
  max-width: 500px;
  height: 180px;
  padding: 25px;
  box-sizing: border-box;

  img {
    display: block;
    width: 100%;
  }

  p {
    margin-top: 50px;
    margin-bottom: 0px;
    font-size: 1.5em;
    color: #fff;
    text-transform: uppercase;
    font-weight: 300;
    text-align: center;
  }
`;

function SimBroadcasts() {
  return (
    <HoldingPage>
      <Helmet>
        <title>Sim Broadcasts</title>
      </Helmet>
      <HoldingMessage>
        <img src={logo} alt="Sim Broadcasts Logo" />
        <p>Coming Soon</p>
      </HoldingMessage>
    </HoldingPage>
  );
}

export default SimBroadcasts;
