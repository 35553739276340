import React from "react";
import styled, { css } from "styled-components";

const StyledChannels = styled.div`
  margin-bottom: 25px;
  display: flex;
  padding: 5px 5px 5px 15px;
  box-sizing: border-box;
  height: 45px;
  color: white;
  /* background: linear-gradient(to bottom left, #303030, #101010); */
  background: linear-gradient(to bottom left, #2e3f67, #0d1938);
  line-height: 35px;

  @media (max-width: 700px) {
    padding-left: 5px;
  }

  @media (max-width: 500px) {
    margin-bottom: 10px;
  }
`;

const ChannelsHeading = styled.h2`
  text-transform: uppercase;
  text-align: center;
  margin: 0px;
  margin-right: 15px;
  font-size: 1.2em;

  @media (max-width: 700px) {
    display: none;
  }
`;

const ChannelGroup = styled.div`
  display: flex;
  flex-grow: 1;
`;

const StyledChannel = styled.a(
  ({ channel }) => css`
    width: 50%;
    height: 35px;
    padding: 0px 15px;
    box-sizing: border-box;
    line-height: 35px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.1);
    ${
      "" /* background-color: ${channel === "twitch" ? "#9146FF55" : "#FF000055"}; */
    }
    ${"" /* background-color: white; */}
    text-decoration: none;
    color: ${channel === "twitch" ? "#9146FF" : "#FF0000"};
    color: white;
    transition: all 0.2s ease-out;

    i {
      margin-right: 8px;
    }

    &:nth-child(odd) {
      margin-right: 5px;

      @media (max-width: 500px) {
        margin-right: 4px;
      }
    }

    &:hover {
      color: white;
      background-color: ${channel === "twitch" ? "#9146FF" : "#FF0000"};
      transition: all 0.2s ease-out;
    }
  `
);

const Channel = ({ channel, href }) => {
  let channelIcon = channel === "twitch" ? "fab fa-twitch" : "fab fa-youtube";
  let channelName = channel === "twitch" ? "Twitch" : "YouTube";

  return (
    <StyledChannel channel={channel} href={href}>
      <i className={channelIcon} />
      <span>{channelName}</span>
    </StyledChannel>
  );
};

const Channels = (props) => {
  return (
    <StyledChannels>
      <ChannelsHeading>Watch our content on:</ChannelsHeading>
      <ChannelGroup>
        <Channel channel="twitch" href="https://twitch.tv/simbroadcasts" />
        <Channel channel="youtube" href="https://youtube.com/simbroadcasts" />
      </ChannelGroup>
    </StyledChannels>
  );
};

export default Channels;
