import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import SimBroadcasts from "./SimBroadcasts";

ReactDOM.render(
  <React.StrictMode>
    <SimBroadcasts />
  </React.StrictMode>,
  document.getElementById("root")
);
