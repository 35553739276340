import React from "react";
import styled from "styled-components";
import { ScrollTop } from "./";

const StyledWrapper = styled.div`
  box-sizing: border-box;
  max-width: 900px;
  margin: 0 auto;
  height: 100%;
  max-height: 100%;
  padding: 25px;
  min-width: 350px;

  @media (max-width: 500px) {
    padding: 10px;
  }
`;

const Wrapper = (props) => (
  <StyledWrapper>
    <ScrollTop />
    {props.children}
  </StyledWrapper>
);

export default Wrapper;
