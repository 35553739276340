import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  Home,
  About,
  // Technology,
  Calendar,
  CalendarArchive,
  Bookings,
  Voice,
  Media,
  Join,
  LFSCommentatorEn,
  LFSVideographer,
  LFSDiscordBot,
  Corporate,
  Shop,
  Supporters,
  Vote,
  Error,
} from "./routes";
import { Wrapper, Header, Footer } from "./routes/components";

function SimBroadcasts() {
  return (
    <Router>
      <Wrapper>
        <Header />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/about">
            <About />
          </Route>
          {/* <Route exact path="/technology">
            <Technology />
          </Route> */}
          <Route exact path="/calendar">
            <Calendar />
          </Route>
          <Route exact path="/calendar/archive">
            <CalendarArchive />
          </Route>
          <Route exact path="/bookings">
            <Bookings />
          </Route>
          <Route exact path="/shop">
            <Shop />
          </Route>
          <Route exact path="/voice">
            <Voice />
          </Route>
          <Route exact path="/media">
            <Media />
          </Route>
          <Route exact path="/join">
            <Join />
          </Route>
          <Route exact path="/join/lfs-commentator-en">
            <LFSCommentatorEn />
          </Route>
          <Route exact path="/join/lfs-videographer">
            <LFSVideographer />
          </Route>
          <Route exact path="/corporate">
            <Corporate />
          </Route>
          <Route exact path="/lfsdiscordbot">
            <LFSDiscordBot />
          </Route>
          <Route exact path="/supporters">
            <Supporters />
          </Route>
          <Route
            path="/soccar"
            component={() => {
              window.location.href =
                "https://docs.google.com/spreadsheets/d/1f73zvoNk1qvhcGw8CXnC3MlfkPK-pwseRY0ZcV3u0ZI";
              return null;
            }}
          />
          <Route exact path="/vote">
            <Vote />
          </Route>
          <Route path="*">
            <Error />
          </Route>
        </Switch>
        <Footer />
      </Wrapper>
    </Router>
  );
}

export default SimBroadcasts;
