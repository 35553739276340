import styled from "styled-components";

const Table = styled.table`
  border-collapse: separate;
  border-spacing: 10px 4px;

  th {
    text-align: left;
  }
  td {
    margin-right: 10px;

    &:last-child {
      margin-right: 0px;
    }
  }
`;

export default Table;
