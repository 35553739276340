import React from "react";
import styled from "styled-components";
import { Main, BroadcastStatusBar, RequestBookingBanner } from "./components";
import { Helmet } from "react-helmet";
import step3 from "../assets/lfsdiscordbot/step-3.png";
import step4 from "../assets/lfsdiscordbot/step-4.png";
import step5 from "../assets/lfsdiscordbot/step-5.png";

const Hero = styled.div`
  width: 100%;
  height: 100px;
  margin-bottom: 25px;
  /* background-color: #2e3f67; */
  background: linear-gradient(to bottom left, #2e3f67, #0d1938);
  color: #ffffff;
  text-align: center;
  height: 100px;
  line-height: 100px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 2.5em;
  overflow: hidden;

  h1 {
    background: linear-gradient(to bottom, #ffffff 0%, #ffffff 30%, #aaaaaa);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0px;
    font-size: inherit;
  }

  @media (max-width: 500px) {
    font-size: 2em;
    height: 75px;
    line-height: 75px;
    margin-bottom: 10px;
  }
`;

const TextPane = styled.article`
  padding: 25px;

  a {
    color: #333;

    &[name] {
      text-decoration: none;
      display: block;

      &:not(:first-child) {
        margin-top: 50px;
      }
    }

    @media (prefers-color-scheme: dark) {
      color: #ddd;
    }

    h2 {
      position: relative;
      &::before {
        position: absolute;
        left: -1.5em;
        padding-right: 1.5em;
        line-height: 1.6em;
        font-size: 0.7em;
        font-family: "Font Awesome 5 Pro";
        content: "\uf0c1";
        color: #bbb;
        opacity: 0;
        user-select: none;
        cursor: normal;
      }

      &:hover::before {
        opacity: 1;
      }

      @media (prefers-color-scheme: dark) {
        color: #fff;
      }
    }
  }

  h2 {
    text-transform: uppercase;
    margin-top: 50px;

    &:first-child {
      margin-top: 0px;
    }

    @media (prefers-color-scheme: dark) {
      color: #fff;
    }
  }

  h3 {
    margin-top: 40px;
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0px;
    margin-bottom: 5px;
    font-style: italic;
    font-size: 1em;
    color: #555;

    & + p {
      margin-top: 5px;
      margin-bottom: 15px;
    }

    @media (prefers-color-scheme: dark) {
      color: white;
    }
  }

  i {
    margin-right: 5px;
    color: #555;

    @media (prefers-color-scheme: dark) {
      color: #bbb;
    }
  }

  ${"" /* li:nth-last-child(2), */}
  .iracing-only {
    &::after {
      color: #999;
      content: " - Currently for iRacing only";
    }
  }

  .lfs-only {
    &::after {
      color: #999;
      content: " - Currently for Live for Speed only";
    }
  }

  .asterisk {
    &::after {
      color: #999;
      content: " - Addon: Produced to your specification";
    }
  }

  p,
  li {
    line-height: 1.5em;
  }

  @media (prefers-color-scheme: dark) {
    color: #fff;
  }
`;

const Button = styled.a`
  text-transform: uppercase;
  text-decoration: none;
  background-color: #0082ff;
  color: #ffffff;
  height: 35px;
  line-height: 35px;
  padding: 0px 15px;
  font-weight: 700;
  margin-right: 15px;
  flex-shrink: 0;
  box-sizing: border-box;
  flex: 1 1;
  text-align: center;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.75;
  }

  &:last-child {
    margin-right: 0px;
  }

  span {
    color: #ffffff;
  }

  i {
    color: #ffffff;
    margin-right: 10px;
  }

  @media (max-width: 650px) {
    flex: 0 0 100%;
    margin: 0px;
    margin-bottom: 10px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 10px;
`;

function LFSDiscordBot() {
  return (
    <Main>
      <Helmet>
        <title>Sim Broadcasts | LFS Discord Bot</title>
      </Helmet>
      <BroadcastStatusBar />
      <Hero>
        <h1>LFS Discord Bot</h1>
      </Hero>
      <TextPane>
        <a name="introduction" href="#introduction">
          <h2>Introduction</h2>
        </a>
        <p>The Live for Speed Discord Bot provides the following utilities:</p>
        <ul>
          <li>
            LFS Username Verification via the official{" "}
            <a href="https://lfs.net">lfs.net</a> OAuth2 API.
          </li>
          <li>More features coming soon...</li>
        </ul>
        <a name="commands" href="#commands">
          <h2>Slash Commands</h2>
        </a>
        <h3>Public Commands</h3>
        <ul>
          <li>
            <h4>
              <code>/verify</code>
            </h4>
            <p>
              Verifies your LFS username with{" "}
              <a href="https://lfs.net">lfs.net</a> and assigns you special
              roles on the Discord server to confirm your LFS license level
              (Demo, S1, S2 or S3).
            </p>
          </li>
        </ul>
        <h3>Admin Commands</h3>
        <ul>
          <li>
            <h4>
              <code>/role [level] [role]</code>
            </h4>
            <p>
              Sets a role which should be assigned to every verified LFS user
              according to their license level. There is also a special
              "verified" role which is assigned to every LFS user.
            </p>
          </li>
          <li>
            <h4>
              <code>/roles</code>
            </h4>
            <p>Lists all assigned roles for verified users.</p>
          </li>
          <li>
            <h4>
              <code>/log</code>
            </h4>
            <p>
              Sets a channel for receiving important debug messages from the
              bot.
            </p>
          </li>
        </ul>
        <a name="installation" href="#installation">
          <h2>Installation</h2>
        </a>
        <h3>1. Add to LFS Discord Bot to your Discord Server</h3>
        <p>
          Use the following link to install the LFS Discord Bot to your Discord
          server:
        </p>
        <ButtonGroup>
          <Button
            href="https://discord.com/oauth2/authorize?client_id=1034201190189236284"
            target="_blank"
          >
            <i className="fab fa-discord"></i>{" "}
            <span>Get the LFS Discord Bot</span>
          </Button>
        </ButtonGroup>
        <p>
          The bot will request the <code>Manage Roles</code> permission during
          installation.
        </p>
        <h3>2. Create Discord roles for verified users</h3>
        <p>
          Your must define roles for all LFS licenses and one for all verified
          users:
        </p>
        <ul>
          <li>LFS verified (everyone who is verified)</li>
          <li>Demo</li>
          <li>S1 licensed</li>
          <li>S2 licensed</li>
          <li>S3 licensed</li>
        </ul>
        <h3>3. Assign roles to the LFS Discord Bot</h3>
        <p>
          use the <code>role</code> command to assign all created user roles to
          the LFS Discord Bot, for example:
        </p>
        <p>
          <code>/role verified (all licenses) @LFS Verified</code>
        </p>
        <a href={step3} rel="noopener noreferrer" target="_blank">
          <img
            style={{ width: "75%" }}
            src={step3}
            alt="use the role cmmand to assign all created user roles to the LFS discord bot"
            title="use the role cmmand to assign all created user roles to the LFS discord bot"
          />
        </a>
        <h3>4. Assign a text channel for receiving messages from the bot</h3>
        <p>
          A message will be sent in that channel when someone has verified
          successfully or if they failed to verify. It will contain the user's
          Discord username and their LFS username.
        </p>
        <p>
          Consider assigning this to a private channel for admins/moderators
          only. For example
        </p>
        <p>
          <code>/log #bot-log</code>
        </p>
        <a href={step4} rel="noopener noreferrer" target="_blank">
          <img
            src={step4}
            style={{ width: "75%" }}
            alt="Assign a private channel for bot messages"
            title="Assign a private channel for bot messages"
          />
        </a>
        <h3>
          5. Make sure the Live for Speed role is ordered above the roles which
          will be assigned to verified users
        </h3>
        <p>To change the order, go to Server Settings &gt; roles.</p>
        <a href={step5} rel="noopener noreferrer" target="_blank">
          <img
            src={step5}
            style={{ width: "75%" }}
            alt="make sure the Live for Speed role is ordered above the roles which will be assigned to verified users"
            title="make sure the Live for Speed role is ordered above the roles which will be assigned to verified users"
          />
        </a>
        <p>
          Everyone will now be able to use the <code>/verify</code> command on
          your server.
        </p>
        <a name="issues" href="#issues">
          <h2>Issues</h2>
        </a>
        <p>
          If you notice an issue with the bot or want to know more, please{" "}
          <a href="mailto:contact@simbroadcasts.tv">contact us</a>.
        </p>
      </TextPane>
      <RequestBookingBanner />
    </Main>
  );
}

export default LFSDiscordBot;
