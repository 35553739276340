import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { Main, BroadcastStatusBar, RequestBookingBanner } from "./components";
import { Helmet } from "react-helmet";

const Hero = styled.div`
  width: 100%;
  height: 100px;
  margin-bottom: 25px;
  /* background-color: #2e3f67; */
  background: linear-gradient(to bottom left, #2e3f67, #0d1938);
  color: #ffffff;
  text-align: center;
  height: 100px;
  line-height: 100px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 2.5em;
  overflow: hidden;

  h1 {
    background: linear-gradient(to bottom, #ffffff 0%, #ffffff 30%, #aaaaaa);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0px;
    font-size: inherit;
  }

  @media (max-width: 500px) {
    font-size: 2em;
    height: 75px;
    line-height: 75px;
    margin-bottom: 10px;
  }
`;

const TextPane = styled.article`
  padding: 25px;

  a {
    color: #333;

    &[name] {
      text-decoration: none;
      display: block;

      &:not(:first-child) {
        margin-top: 50px;
      }
    }

    @media (prefers-color-scheme: dark) {
      color: #ddd;
    }

    h2 {
      position: relative;
      &::before {
        position: absolute;
        left: -1.5em;
        padding-right: 1.5em;
        line-height: 1.6em;
        font-size: 0.7em;
        font-family: "Font Awesome 5 Pro";
        content: "\uf0c1";
        color: #bbb;
        opacity: 0;
        user-select: none;
        cursor: normal;
      }

      &:hover::before {
        opacity: 1;
      }

      @media (prefers-color-scheme: dark) {
        color: #fff;
      }
    }
  }

  h2 {
    text-transform: uppercase;
    margin-top: 50px;

    &:first-child {
      margin-top: 0px;
    }

    @media (prefers-color-scheme: dark) {
      color: #fff;
    }
  }

  h3 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0px;
    margin-bottom: 5px;
    font-style: italic;
    font-size: 1em;
    color: #555;

    & + p {
      margin-top: 5px;
      margin-bottom: 0px;
    }

    @media (prefers-color-scheme: dark) {
      color: #bbb;
    }
  }

  i {
    margin-right: 5px;
    color: #555;

    @media (prefers-color-scheme: dark) {
      color: #bbb;
    }
  }

  ${"" /* li:nth-last-child(2), */}
  .iracing-only {
    &::after {
      color: #999;
      content: " - Currently for iRacing only";
    }
  }

  .lfs-only {
    &::after {
      color: #999;
      content: " - Currently for Live for Speed only";
    }
  }

  .asterisk {
    &::after {
      color: #999;
      content: " - Addon: Produced to your specification";
    }
  }

  p,
  li {
    line-height: 1.5em;
  }

  @media (prefers-color-scheme: dark) {
    color: #fff;
  }
`;

const Form = styled.form`
  margin-top: 30px;
`;

const FormItem = styled.div`
  flex: 1;
  margin: 0px 10px;

  &:first-child {
    margin-left: 0px;
  }

  &:last-child {
    margin-right: 0px;
  }

  a {
    text-decoration: none;
    strong {
      font-size: 1.1em;
      margin-right: 4px;
    }
  }

  @media (max-width: 700px) {
    margin: 0px 0px 30px 0px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

const FormRow = styled.div`
  display: flex;
  margin-bottom: 30px;

  @media (max-width: 700px) {
    &.radios div:nth-child(2) {
      margin: 0px;
    }
  }

  @media (max-width: 650px) {
    &.radios {
      flex-direction: column;

      & div:nth-child(2) {
        margin: 0px 0px 10px 0px;
      }

      & div {
        margin: 0px 0px 10px 0px;
      }
    }
  }

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const Avatar = styled.div(
  ({ member }) => css`
    float: left;
    width: 80px;
    height: 80px;
    background-image: url(assets/team/sbtv-${member ? member : "default"}.png);
    background-repeat: no-repeat;
    background-size: 80px;
    margin-right: 20px;
  `
);

function About() {
  return (
    <Main>
      <Helmet>
        <title>Sim Broadcasts | About Us</title>
      </Helmet>
      <BroadcastStatusBar />
      <Hero>
        <h1>About Us</h1>
      </Hero>
      <TextPane>
        <a name="who-are-sim-broadcasts" href="#who-are-sim-broadcasts">
          <h2>Who are Sim Broadcasts?</h2>
        </a>
        <p>
          We specialise in livestreaming sim racing content, giving your event
          the broadcast it deserves. We are currently focused on providing
          commentary and streaming services to events in{" "}
          <a href="https://lfs.net">Live for Speed</a> and{" "}
          <a href="https://iracing.com">iRacing</a>.
        </p>
        <a name="what-we-offer" href="#what-we-offer">
          <h2>What we Offer</h2>
        </a>
        <p>
          Whether you require a commentary team for a single event or a full
          broadcast package for an entire season, we have you covered.
        </p>
        <ul className="offerings">
          <li>Expert Commentary</li>
          <li>Real-Time Graphical Overlays</li>
          <li className="lfs-only">Advanced Camera System</li>
          <li>Mid and Post-Race TeamSpeak Interviews</li>
          <li>Event Results and Points</li>
          <li>Multi-platform Livestream</li>
          <li>Livestream Replay on YouTube</li>
          <li>Race Highlights Package for Endurance Series</li>
          <li className="iracing-only">Live Timing</li>
          <li className="asterisk">Series Video Trailer Production</li>
        </ul>
        <p>
          Please refer to our <Link to="voice">voice</Link> page for more
          information about how our mid and post-race interview process works.
        </p>
        <a name="corporate-services" href="#corporate-services">
          <h2>Corporate Services</h2>
        </a>
        <p>
          <Link to="corporate">Click here</Link> to read our corporate services
          brochure for full details on our range of services that we can offer
          your company or brand before you{" "}
          <Link to="bookings">request your broadcast</Link> package.
        </p>
        <a name="team" href="#team">
          <h2>Meet the Team</h2>
        </a>
        <p>
          The Sim Broadcasts team have a wide range of backgrounds, but each
          with a passion for racing.
        </p>
        <Form>
          <FormRow>
            <FormItem>
              <Avatar member="pete" />
              <h3>Peter Butcher</h3>
              <h4>Co-Founder, Developer and Commentator</h4>
              <p>
                <a href="https://twitter.com/pwsbutcher">
                  {/* <i className="fab fa-twitter"></i> */}
                  <strong>𝕏</strong>
                </a>
                <a href="https://www.lfs.net/profile/29922">
                  <i className="far fa-steering-wheel"></i>
                </a>
                <a href="https://pbutcher.uk">
                  <i className="far fa-globe"></i>
                </a>
                <a href="mailto:pete@simbroadcasts.tv">
                  <i className="far fa-envelope"></i>
                </a>
              </p>
            </FormItem>
            <FormItem>
              <Avatar member="chrism" />
              <h3>Chris McElroy</h3>
              <h4>Co-Founder, Developer and Commentary Liaison</h4>
              <p>
                <a href="https://www.lfs.net/profile/100259">
                  <i className="far fa-steering-wheel"></i>
                </a>
                <a href="mailto:chris@simbroadcasts.tv">
                  <i className="far fa-envelope"></i>
                </a>
              </p>
            </FormItem>
          </FormRow>
          <FormRow>
            <FormItem>
              <Avatar member="jonathan" />
              <h3>Jonathan Palmer</h3>
              <h4>Co-Founder, Developer and Commentator</h4>
              <p>
                <a href="https://www.lfs.net/profile/122006">
                  <i className="far fa-steering-wheel"></i>
                </a>
                <a href="mailto:palmer@simbroadcasts.tv">
                  <i className="far fa-envelope"></i>
                </a>
              </p>
            </FormItem>
            <FormItem>
              <Avatar member="eoin" />
              <h3>Eoin Harmon</h3>
              <h4>Commentator</h4>
              <p>
                <a href="https://twitter.com/Eoin_Harmon">
                  {/* <i className="fab fa-twitter"></i> */}
                  <strong>𝕏</strong>
                </a>
                <a href="https://www.lfs.net/profile/203459">
                  <i className="far fa-steering-wheel"></i>
                </a>
              </p>
            </FormItem>
          </FormRow>
          <FormRow>
            <FormItem>
              <Avatar member="travis" />
              <h3>Travis Mehlinger</h3>
              <h4>Developer and Commentator</h4>
              <p>
                <a href="https://twitter.com/maber">
                  {/* <i className="fab fa-twitter"></i> */}
                  <strong>𝕏</strong>
                </a>
                <a href="https://www.lfs.net/profile/196636">
                  <i className="far fa-steering-wheel"></i>
                </a>
              </p>
            </FormItem>
            <FormItem>
              <Avatar member="martin" />
              <h3>Martin Kapal</h3>
              <h4>Developer</h4>
              <p>
                <a href="https://twitter.com/MartinKapal">
                  {/* <i className="fab fa-twitter"></i> */}
                  <strong>𝕏</strong>
                </a>
                <a href="https://youtube.com/c/MartinKapal">
                  <i className="fab fa-youtube"></i>
                </a>
                <a href="https://www.lfs.net/profile/182784">
                  <i className="far fa-steering-wheel"></i>
                </a>
              </p>
            </FormItem>
          </FormRow>
          <FormRow>
            <FormItem>
              <Avatar member="thilo" />
              <h3>Thilo Falkenberg</h3>
              <h4>Graphics and Commentator</h4>
              <p>
                <a href="https://twitter.com/tfalke55">
                  {/* <i className="fab fa-twitter"></i> */}
                  <strong>𝕏</strong>
                </a>
                <a href="https://www.youtube.com/user/TFalke55">
                  <i className="fab fa-youtube"></i>
                </a>
                <a href="https://www.lfs.net/profile/87248">
                  <i className="far fa-steering-wheel"></i>
                </a>
              </p>
            </FormItem>
            <FormItem>
              <Avatar />
              <h3>Dániel Balla</h3>
              <h4>Videographer</h4>
              <p>
                <a href="https://twitter.com/Mandula901">
                  {/* <i className="fab fa-twitter"></i> */}
                  <strong>𝕏</strong>
                </a>
                <a href="https://www.youtube.com/channel/UCzst08wFUBAG9kh-H931-6w">
                  <i className="fab fa-youtube"></i>
                </a>
                <a href="https://www.lfs.net/profile/596974">
                  <i className="far fa-steering-wheel"></i>
                </a>
              </p>
            </FormItem>
          </FormRow>
          <FormRow>
            <FormItem>
              <Avatar member="michal" />
              <h3>Michal Málek</h3>
              <h4>Videographer and Commentator</h4>
              <p>
                <a href="https://twitter.com/michal1279">
                  {/* <i className="fab fa-twitter"></i> */}
                  <strong>𝕏</strong>
                </a>
                <a href="https://www.youtube.com/user/MrMichalMalek">
                  <i className="fab fa-youtube"></i>
                </a>
                <a href="https://www.lfs.net/profile/890289">
                  <i className="far fa-steering-wheel"></i>
                </a>
              </p>
            </FormItem>
            <FormItem>
              <Avatar member="miika" />
              <h3>Miika Särkkinen</h3>
              <h4>Commentator</h4>
              <p>
                <a href="https://www.lfs.net/profile/407567">
                  <i className="far fa-steering-wheel"></i>
                </a>
              </p>
            </FormItem>
          </FormRow>
          <FormRow>
            <FormItem>
              <Avatar member="zdravko" />
              <h3>Zdravko Topolnjak</h3>
              <h4>Commentator</h4>
              <p>
                <a href="https://www.lfs.net/profile/https://www.lfs.net/profile/223700">
                  <i className="far fa-steering-wheel"></i>
                </a>
              </p>
            </FormItem>
            <FormItem>
              <Avatar member="zdenek" />
              <h3>Zdeněk Cagaš</h3>
              <h4>Commentator</h4>
              <p>
                <a href="https://www.lfs.net/profile/80063">
                  <i className="far fa-steering-wheel"></i>
                </a>
              </p>
            </FormItem>
          </FormRow>
          <FormRow>
            <FormItem>
              <Avatar member="tom" />
              <h3>Tom Jones</h3>
              <h4>Commentator</h4>
              <p>
                <a href="https://www.lfs.net/profile/2156798">
                  <i className="far fa-steering-wheel"></i>
                </a>
              </p>
            </FormItem>
            <FormItem>
              <Avatar member="andy" />
              <h3>Andy Alston</h3>
              <h4>Commentator</h4>
              <p>
                <a href="https://twitter.com/andyrocksport">
                  {/* <i className="fab fa-twitter"></i> */}
                  <strong>𝕏</strong>
                </a>
              </p>
            </FormItem>
          </FormRow>
          <FormRow>
            <FormItem>
              <Avatar member="chrisf" />
              <h3>Chris Ford</h3>
              <h4>Commentator</h4>
              <p>
                <a href="https://twitter.com/foreveralead">
                  {/* <i className="fab fa-twitter"></i> */}
                  <strong>𝕏</strong>
                </a>
                <a href="https://www.lfs.net/profile/201542">
                  <i className="far fa-steering-wheel"></i>
                </a>
              </p>
            </FormItem>
            <FormItem>
              <Avatar member="adam" />
              <h3>Adam Brown</h3>
              <h4>Commentator</h4>
              <p>
                <a href="https://www.lfs.net/profile/1016373">
                  <i className="far fa-steering-wheel"></i>
                </a>
              </p>
            </FormItem>
          </FormRow>
          <FormRow>
            <FormItem>
              <Avatar member="jack" />
              <h3>Jack Eyre</h3>
              <h4>Commentator</h4>
              <p>
                <a href="https://twitter.com/v_iRenegade">
                  {/* <i className="fab fa-twitter"></i> */}
                  <strong>𝕏</strong>
                </a>
                <a href="https://www.youtube.com/user/zRenegadeGaming">
                  <i className="fab fa-youtube"></i>
                </a>
                <a href="https://www.lfs.net/profile/1356037">
                  <i className="far fa-steering-wheel"></i>
                </a>
              </p>
            </FormItem>
            <FormItem>
              <Avatar member="victor" />
              <h3>Victor Paulo</h3>
              <h4>Videographer</h4>
              <p>
                <a href="https://www.instagram.com/lfs_edits/">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="https://www.youtube.com/c/ArceB225">
                  <i className="fab fa-youtube"></i>
                </a>
                <a href="https://www.lfs.net/profile/1800623">
                  <i className="far fa-steering-wheel"></i>
                </a>
              </p>
            </FormItem>
          </FormRow>
          <FormRow>
            <FormItem>
              <Avatar />
              <h3>Dave Fraser</h3>
              <h4>Commentator</h4>
              <p>
                <a href="https://www.lfs.net/profile/183883">
                  <i className="far fa-steering-wheel"></i>
                </a>
              </p>
            </FormItem>
          </FormRow>
        </Form>
        <a name="join" href="#join">
          <h2>Join the Team</h2>
        </a>
        <p>
          We are currently <Link to="/join">accepting applications</Link> for
          roles within our team.
        </p>
        <a name="resources" href="#resources">
          <h2>Resources</h2>
        </a>
        <ul>
          <li>
            Logos and usage guide: <Link to="/media">Media Pack</Link>
          </li>
        </ul>
      </TextPane>
      <RequestBookingBanner />
    </Main>
  );
}

export default About;
