import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import calendar from "../../calendar.json";

const StyledEvent = styled.article(
  ({ sim, env }) => css`
    background-image: url(assets/${sim}-environments/${env}.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    margin-bottom: 25px;
    ${"" /* min-height: 205px; */}

    @media (max-width: 500px) {
      margin-bottom: 10px;
    }
  `
);

const NextHeader = styled.div`
  height: 45px;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 5px;
`;

const NextUpHeading = styled.h2`
  color: white;
  font-size: 1.2em;
  text-transform: uppercase;
  margin: 0px 0px 0px 10px;
  font-weight: 700;
`;

const SeeFullBroadcastCalendar = styled(Link)`
  text-transform: uppercase;
  text-decoration: none;
  background-color: #ffffff;
  color: #000000;
  height: 35px;
  line-height: 35px;
  padding: 0px 10px;
  font-weight: 700;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: #666;
  }

  i {
    margin-right: 10px;
  }
`;

const EventBody = styled.div`
  padding: 15px;
  box-sizing: border-box;
  ${"" /* min-height: 160px; */}
  display: flex;
`;

const EventIcon = styled.div(
  ({ icon }) => css`
    background-image: url(assets/series-icons/${icon});
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    width: 130px;
    ${"" /* min-height: 130px; */}

    @media(max-width: 420px) {
      display: none;
    }
  `
);

const EventDetails = styled.div`
  position: relative;
  border-left: solid 2px rgba(255, 255, 255, 0.5);
  margin-left: 15px;
  flex-grow: 1;
  padding-left: 15px;

  @media (max-width: 420px) {
    margin-left: 0px;
    border-left: none;
    padding: 0px;
  }
`;

const EventDetailsRow = styled.div`
  margin-top: 5px;
  display: flex;

  &:first-child {
    margin-top: 0px;
  }
`;

const EventDate = styled.div(
  ({ highlight, highContrast }) => css`
    display: inline-block;
    font-size: 0.8em;
    color: ${highContrast ? "#000" : "#fff"};
    background: ${highlight ? highlight : "#0d58a0"};
    font-weight: 700;
    padding: 5px 10px;
    text-transform: uppercase;
  `
);

const EventName = styled.div`
  display: inline-block;
  font-size: 1.2em;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  font-weight: 700;
  padding: 5px 10px;
  text-transform: uppercase;
`;

const EventCombination = styled.div`
  display: inline-block;
  font-size: 1em;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  font-weight: 700;
  padding: 5px 10px;
  text-transform: uppercase;
`;

const EventLink = styled.a`
  text-decoration: none;
  background-color: #ffffff;
  color: #000000;
  display: inline-block;
  font-size: 0.8em;
  font-weight: 700;
  padding: 5px 10px;
  text-transform: uppercase;
  margin-right: 5px;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: #666;
  }

  i {
    margin-right: 10px;
  }

  &:last-child {
    margin-right: 0px;
  }

  @media (max-width: 800px) {
    span {
      display: none;
    }
    i {
      margin: 0;
    }
  }
`;

const SimIcon = styled.div(
  ({ icon }) => css`
    background-image: url(assets/sim-icons/${icon}.png);
    width: ${icon === "lfs" ? "75px" : "32px"};
    height: 25px;
    background-size: 100%;
    background-repeat: no-repeat;
    position: absolute;
    background-position: center;
    top: 0px;
    right: 0px;
  `
);

const EventType = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;
  font-size: 1.6em;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;

  @media (max-width: 700px) {
    font-size: 1.3em;
  }
`;

// const MiniSeriesLogo = styled.div(({ hc, event }) => {
//   return css`
//     display: none;
//     width: 21px;
//     height: 21px;
//     background-image: url(../assets/series-icons/${event});
//     background-repeat: no-repeat;
//     background-size: 21px;
//     background-position: center;
//     padding: 2px;
//     margin-right: 5px;
//     ${"" /* background-color: ${hc ? "black" : "white"}; */}
//     background-color: black;

//     @media (prefers-color-scheme: light) {
//       background-color: #2e3f67;
//       border-radius: 4px;
//     }

//     @media (max-width: 420px) {
//       display: block;
//     }
//   `;
// });

const Event = (props) => {
  let event = props.event;
  let series = calendar.series.find((el) => el.name === event.series);
  let icon = series.icon;
  let date = event.date_string;
  let name = `${series.organiser} ${series.series} - ${event.name}`;
  let combination = `${event.track} - ${event.car}`;
  let seriesWebsite = series.website;
  let broadcast = series.broadcast;
  let broadcastPlatform = series.broadcast_platform;
  let sim = series.sim;
  let eventType = event.type;
  let highlight = series.highlight_colour;
  let highContrast = series.high_contrast;

  let env;

  switch (sim) {
    case "lfs": {
      env = event.track.slice(0, 2).toLowerCase();
      const lfsenvs = [
        "bl",
        "fe",
        "au",
        "ky",
        "we",
        "au",
        "so",
        "ro",
        "as",
        "la",
      ];
      // If the event is on a custom configuration
      if (!lfsenvs.includes(env) && event.track !== "tbc") {
        env = "custom";
      } else if (event.track === "tbc") {
        env = "tbc";
      }
      break;
    }
    case "iracing": {
      env = event.track.split(" ")[0].toLowerCase();
      const irenvs = ["knockhill", "nurburgring", "rudskogen", "road"];
      if (!irenvs.includes(env) && event.track !== "tbc") {
        env = "placeholder";
      } else if (event.track === "tbc") {
        env = "tbc";
      }
      // console.log(env, sim);
      break;
    }
    default:
      break;
  }

  return (
    <StyledEvent sim={sim} env={env}>
      {props.id === 0 ? (
        <NextHeader>
          <NextUpHeading>Next Up</NextUpHeading>
          {props.cal ? (
            <SeeFullBroadcastCalendar to="calendar">
              <i className="far fa-calendar"></i>
              <span>Broadcast calendar</span>
            </SeeFullBroadcastCalendar>
          ) : (
            <SeeFullBroadcastCalendar to="calendar/archive">
              <i className="far fa-archive"></i>
              <span>Broadcast archive</span>
            </SeeFullBroadcastCalendar>
          )}
        </NextHeader>
      ) : null}
      <EventBody>
        <EventIcon icon={icon} />
        <EventDetails>
          <EventDetailsRow>
            {/* <MiniSeriesLogo event={icon} sim={sim} hc={highContrast} /> */}
            <EventDate highlight={highlight} highContrast={highContrast}>
              {date}
            </EventDate>
          </EventDetailsRow>
          <EventDetailsRow>
            <EventName>{name}</EventName>
          </EventDetailsRow>
          <EventDetailsRow>
            <EventCombination>{combination}</EventCombination>
          </EventDetailsRow>
          <EventDetailsRow>
            <EventLink href={seriesWebsite}>
              <i className="far fa-globe"></i>
              <span>Series Website</span>
            </EventLink>
            <EventLink href={broadcast}>
              <i className={`fab fa-${broadcastPlatform}`}></i>
              <span>Series Broadcast</span>
            </EventLink>
          </EventDetailsRow>
          <SimIcon icon={sim} />
          <EventType>{eventType}</EventType>
        </EventDetails>
      </EventBody>
    </StyledEvent>
  );
};

export default Event;
