import React from "react";
import styled from "styled-components";
import { Main, BroadcastStatusBar, RequestBookingBanner } from "./components";
import full from "../assets/media/full.png";
import icon from "../assets/media/icon.png";
import { Helmet } from "react-helmet";

const Hero = styled.div`
  width: 100%;
  height: 100px;
  margin-bottom: 25px;
  background: linear-gradient(to bottom left, #2e3f67, #0d1938);
  /* background-color: #2e3f67; */
  color: #ffffff;
  text-align: center;
  height: 100px;
  line-height: 100px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 2.5em;
  overflow: hidden;

  h1 {
    background: linear-gradient(to bottom, #ffffff 0%, #ffffff 30%, #aaaaaa);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0px;
    font-size: inherit;
  }

  @media (max-width: 500px) {
    font-size: 2em;
    height: 75px;
    line-height: 75px;
    margin-bottom: 10px;
  }
`;

const TextPane = styled.article`
  padding: 25px;

  a {
    color: #333;

    &[name] {
      text-decoration: none;
      display: block;

      &:not(:first-child) {
        margin-top: 50px;
      }
    }

    @media (prefers-color-scheme: dark) {
      color: #ddd;
    }
  }

  h2 {
    position: relative;
    &::before {
      position: absolute;
      left: -1.5em;
      padding-right: 1.5em;
      line-height: 1.6em;
      font-size: 0.7em;
      font-family: "Font Awesome 5 Pro";
      content: "\uf0c1";
      color: #bbb;
      opacity: 0;
      user-select: none;
      cursor: normal;
    }

    &:hover::before {
      opacity: 1;
    }

    @media (prefers-color-scheme: dark) {
      color: #fff;
    }
  }

  h2 {
    text-transform: uppercase;
    margin-top: 50px;

    &:first-child {
      margin-top: 0px;
    }
  }

  h3 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0px;
    margin-bottom: 5px;
    font-style: italic;
    color: #555;

    & + p {
      margin-top: 5px;
      margin-bottom: 0px;
    }
  }

  i {
    margin-right: 5px;
    color: #555;
  }

  p,
  li {
    line-height: 1.5em;
  }

  @media (prefers-color-scheme: dark) {
    color: #fff;
  }
`;

const Button = styled.a`
  text-transform: uppercase;
  text-decoration: none;
  background-color: #0082ff;
  color: #ffffff;
  height: 35px;
  line-height: 35px;
  padding: 0px 15px;
  font-weight: 700;
  margin-right: 15px;
  flex-shrink: 0;
  box-sizing: border-box;
  flex: 1 1;
  text-align: center;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.75;
  }

  &:last-child {
    margin-right: 0px;
  }

  span {
    color: #ffffff;
  }

  i {
    color: #ffffff;
    margin-right: 10px;
  }

  @media (max-width: 650px) {
    flex: 0 0 100%;
    margin: 0px;
    margin-bottom: 10px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 10px;
`;

const Image = styled.img`
  width: 100%;
  margin-bottom: 30px;
`;

function Media() {
  return (
    <Main>
      <Helmet>
        <title>Sim Broadcasts | Media Pack</title>
      </Helmet>
      <BroadcastStatusBar />
      <Hero>
        <h1>Media Pack</h1>
      </Hero>
      <TextPane>
        <a name="usage" href="#usage">
          <h2>Use of our logo</h2>
        </a>
        <p>
          We have put together a logo pack for you to use, subject to a couple
          of terms and conditions.
        </p>
        <p>
          As long as you ensure that you follow our{" "}
          <a href="#guide">usage guide</a>, you may use our logos for the
          following purposes:
        </p>
        <ul>
          <li>Linking back to our website</li>
          <li>
            For inclusion in promotional material if your event is being
            broadcasted by us
          </li>
          <li>
            For inclusion in skin/paint files for events that are being
            broadcasted by us
          </li>
        </ul>
        <p>Please do not:</p>
        <ul>
          <li>
            Edit or modify the Sim Broadcasts logos in any way including colours
            and dimensions
          </li>
          <li>Integrate our logo into your logo</li>
          <li>Use or sell products or services containing our logo</li>
        </ul>
        <p>
          If you have any doubts about how to use our logo, please{" "}
          <a href="mailto:contact@simbroadcasts.tv">get in touch</a>.
        </p>
        <a name="guide" href="#guide">
          <h2>Usage Guide</h2>
        </a>
        <h3>Full Logo</h3>
        <p>
          Our full-width logo consists of our icon and logo text and is suitable
          for all applications where space is not limited and text is still
          readable. This should be the go-to logo unless space or text
          readability may be problematic. It comes in 4 variations:
        </p>
        <ol>
          <li>
            Full-width Colour Icon and White Text - Suitable for dark
            backgrounds
          </li>
          <li>
            Full-width Colour Icon and Black Text - Suitable for light
            backgrounds
          </li>
          <li>Full-width White - Suitable for lighter colourful backgrounds</li>
          <li>Full-width Black - Suitable for darker colourful backgrounds</li>
        </ol>
        <p>
          Always choose the logo with the best overall contrast. You may apply a
          small amount of drop shadow to improve background contrast if
          necessary.
        </p>
        <Image src={full} alt="Full logo usage guide" />

        <h3>Icon</h3>
        <p>
          Our icon should only be considered if the use of our full-width logo
          results in poor readability, eg. a small space on a skin/paint that
          would be unsuitable for small text. It comes in 3 variations:
        </p>
        <ol>
          <li>Colour - Suitable for dark and light backgrounds</li>
          <li>White - Suitable for darker colourful backgrounds</li>
          <li>Black - Suitable for lighter colourful backgrounds</li>
        </ol>
        <p>
          Always choose the icon with the best overall contrast. You may apply a
          small amount of drop shadow to improve background contrast if
          necessary.
        </p>
        <Image src={icon} alt="Icon usage guide" />
        <h3>Download</h3>
        <p>
          Our logos are supplied in PNG format. If you require alternative
          formats, please{" "}
          <a href="mailto:contact@simbroadcasts.tv">get in touch</a>.
        </p>
        <ButtonGroup>
          <Button
            href="https://simbroadcasts.tv/downloads/sbtv-logo-pack.zip"
            target="_blank"
          >
            <i className="far fa-download"></i>{" "}
            <span>Download Logo Pack (287KB)</span>
          </Button>
        </ButtonGroup>
      </TextPane>
      <RequestBookingBanner />
    </Main>
  );
}

export default Media;
