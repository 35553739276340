import React from "react";
import styled from "styled-components";
import { Main, BroadcastStatusBar, RequestBookingBanner } from "./components";
import { Helmet } from "react-helmet";

const Hero = styled.div`
  width: 100%;
  height: 100px;
  margin-bottom: 25px;
  background: linear-gradient(to bottom left, #2e3f67, #0d1938);
  color: #ffffff;
  text-align: center;
  height: 100px;
  line-height: 100px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 2.5em;
  overflow: hidden;

  h1 {
    background: linear-gradient(to bottom, #ffffff 0%, #ffffff 30%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0px;
    font-size: inherit;
  }

  @media (max-width: 500px) {
    font-size: 2em;
    height: 75px;
    line-height: 75px;
    margin-bottom: 10px;
  }
`;

const TextPane = styled.article`
  padding: 25px;

  a {
    color: #333;

    @media (prefers-color-scheme: dark) {
      color: #ddd;
    }
  }

  h2 {
    text-transform: uppercase;
    margin-top: 50px;

    &:first-child {
      margin-top: 0px;
    }

    @media (prefers-color-scheme: dark) {
      color: #fff;
    }
  }

  h3 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0px;
    margin-bottom: 5px;
    font-style: italic;
    color: #555;

    & + p {
      margin-top: 5px;
      margin-bottom: 0px;
    }
  }

  i {
    margin-right: 5px;
    color: #555;
  }

  p,
  li {
    line-height: 1.5em;
  }

  @media (prefers-color-scheme: dark) {
    color: #fff;
  }
`;

const Button = styled.a`
  text-transform: uppercase;
  text-decoration: none;
  background-color: #0082ff;
  color: #ffffff;
  height: 35px;
  line-height: 35px;
  padding: 0px 15px;
  font-weight: 700;
  margin-right: 15px;
  flex-shrink: 0;
  box-sizing: border-box;
  flex: 1 1;
  text-align: center;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.75;
  }

  &:last-child {
    margin-right: 0px;
  }

  span {
    color: #ffffff;
  }

  i {
    color: #ffffff;
    margin-right: 10px;
  }

  @media (max-width: 650px) {
    flex: 0 0 100%;
    margin: 0px;
    margin-bottom: 10px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 10px;
`;

function Voice() {
  return (
    <Main>
      <Helmet>
        <title>Sim Broadcasts | Voice Server</title>
      </Helmet>
      <BroadcastStatusBar />
      <Hero>
        <h1>Voice Server</h1>
      </Hero>
      <TextPane>
        <h2>Join our Teamspeak Server</h2>
        <p>
          If you are competing in a race we are currently broadcasting, you are
          welcome to join our Teamspeak server to participate in mid or
          post-race interviews.
        </p>
        <p>
          Once connected, please join the waiting room channel where voice is
          disabled. This ensures that you are not disturbed by others during
          your race. You may wish to disable other Teamspeak notifications by
          deactivating the default Teamspeak soundpack (Settings &gt; Options
          &gt; Notifications &gt; Sound Pack &gt; Sounds Deactivated) or by
          setting up a hotkey to mute/unmute your Teamspeak audio.
        </p>
        <p>
          <strong>Please note</strong>: Being in the waiting room channel means
          that you are prepared to be interviewed at any time. You will only be
          moved into the commentary booth if your speakers and microphone are
          unmuted.
        </p>
        <p>
          If you have Teamspeak installed, you can connect via:{" "}
          <a href="ts3server://ts.simbroadcasts.tv">ts.simbroadcasts.tv</a>
        </p>
        <ButtonGroup>
          <Button href="https://www.teamspeak.com/en/downloads/">
            <i className="far fa-download"></i> <span>Download Teamspeak</span>
          </Button>
          <Button href="ts3server://ts.simbroadcasts.tv">
            <i className="far fa-user-headset"></i>{" "}
            <span>Connect to SBTV TS3</span>
          </Button>
        </ButtonGroup>
        <h2>Disclaimers</h2>
        <p>
          Use of our Teamspeak server comes with a couple of disclaimers. Please
          make sure you are aware of them.
        </p>

        <ul>
          <li>
            Members of the Sim Broadcasts team should not be disturbed at any
            time on our Teamspeak server so please refrain from messaging or
            poking them. Doing so will result in your immediate removal from the
            server.
          </li>
          <li>
            We will try to interview as many people as possible post-race and
            will prioritise those with the best results in each session. We will
            only interview drivers mid-race if it fits with the race narrative.
          </li>
          <li>
            Please try to act professionally during your interview and avoid any
            profanities. We reserve the right to deny you future interviews if
            you violate these terms.
          </li>
        </ul>
      </TextPane>
      <RequestBookingBanner />
    </Main>
  );
}

export default Voice;
