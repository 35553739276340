import React from "react";
import styled from "styled-components";
// import { Link } from "react-router-dom";
import { Main, BroadcastStatusBar, RequestBookingBanner } from "./components";
import { Helmet } from "react-helmet";

const Hero = styled.div`
  width: 100%;
  height: 100px;
  margin-bottom: 25px;
  background: linear-gradient(to bottom left, #2e3f67, #0d1938);
  /* background-color: #2e3f67; */
  color: #ffffff;
  text-align: center;
  height: 100px;
  line-height: 100px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 2.5em;
  overflow: hidden;

  h1 {
    background: linear-gradient(to bottom, #ffffff 0%, #ffffff 30%, #aaaaaa);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0px;
    font-size: inherit;
  }

  @media (max-width: 500px) {
    font-size: 2em;
    height: 75px;
    line-height: 75px;
    margin-bottom: 10px;
  }
`;

const TextPane = styled.article`
  padding: 25px;

  a {
    color: #333;

    &[name] {
      text-decoration: none;
      display: block;

      &:not(:first-child) {
        margin-top: 50px;
      }
    }

    h2 {
      position: relative;
      &::before {
        position: absolute;
        left: -1.5em;
        padding-right: 1.5em;
        line-height: 1.6em;
        font-size: 0.7em;
        font-family: "Font Awesome 5 Pro";
        content: "\uf0c1";
        color: #bbb;
        opacity: 0;
        user-select: none;
        cursor: normal;
      }

      &:hover::before {
        opacity: 1;
      }
    }
  }

  h2 {
    text-transform: uppercase;
    margin-top: 50px;

    &:first-child {
      margin-top: 0px;
    }
  }

  h3 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0px;
    margin-bottom: 5px;
    font-style: italic;
    color: #555;

    & + p {
      margin-top: 5px;
      margin-bottom: 0px;
    }
  }

  i {
    margin-right: 5px;
    color: #555;
  }

  p,
  li {
    line-height: 1.5em;
  }
`;

function Technology() {
  return (
    <Main>
      <Helmet>
        <title>Sim Broadcasts | Our Technology</title>
      </Helmet>
      <BroadcastStatusBar />
      <Hero>
        <h1>Our Technology</h1>
      </Hero>
      <TextPane>
        <a name="philosophy" href="#philosophy">
          <h2>Our Philosophy</h2>
        </a>
        <p>
          We are developing broadcast software to enhance the production value
          of our sim racing livestreams. Our goal is to provide high quality
          broadcasts for event organisers and their audiences. Here we present
          the technology behind our broadcasts, what we are currently working on
          and our vision for the future.
        </p>
        <p>What's in this article:</p>
        <ul>
          <li>
            <a href="#overlays">Broadcast Overlays</a>
          </li>
          <li>
            <a href="#cameras">Advanced Camera System</a>
          </li>
          <li>
            <a href="#tech">Behind the Scenes</a>
          </li>
          <li>
            <a href="#roadmap">Development Roadmap</a>
          </li>
          <li>
            <a href="#suggestions">Make a Suggestion</a>
          </li>
        </ul>
        <a name="overlays" href="#overlays">
          <h2>Broadcast Overlays</h2>
        </a>
        <p>
          Based on a clean and simple design, our bespoke broadcast overlays
          present viewers with all of the information they need to follow along
          with the racing action, complimenting our expert commentary. The
          timing tower lists real-time driver positions, timing data in all
          sessions and event specific advertisements to showcase series
          partnerships. Other overlays include grid and race result screens,
          race control notifications and detailed information about the cars
          currently on screen.
        </p>
        <a name="cameras" href="#cameras">
          <h2>Advanced Camera System</h2>
        </a>
        <p>
          We have developed an advanced camera system that integrates seamlessly
          with our overlays, allowing us to capture the racing action from a
          vast number of different angles. To ensure that our viewers have the
          best seats in the house we employ numerous onboard cameras tailored to
          every car as well as static and dynamic track-side cameras in the best
          possible vantage points to capture the action.
        </p>
        <a name="tech" href="#tech">
          <h2>Behind the Scenes</h2>
        </a>
        <p></p>
        <a name="roadmap" href="#roadmap">
          <h2>Development Roadmap</h2>
        </a>
        <p>
          We are at the beginning of our journey into sim racing broadcasts and
          have laid out a roadmap for the development of our broadcast software
          suite. We are actively developing the following features and plan
          integrate them into our broadcasts in the near future:
        </p>
        <ul>
          <li>
            Targeted Player
            <ul>
              <li>Gap to next car (every sector) in race</li>
              <li>Sector times in qual and race (black/green/purple)</li>
            </ul>
          </li>
          <li>
            Battle Board
            <ul>
              <li>Show gaps between a subset of drivers in a separate tower</li>
            </ul>
          </li>
          <li>
            Timing Tower
            <ul>
              <li>
                Show remaining 20 drivers 5 at a time at the bottom of the tower
              </li>
              <li>Places gained/lost since session start</li>
              <li>Car and tyre compound indicator</li>
              <li>Pit status indicators</li>
              <li>Penalty indicator</li>
              <li>Display team occasionally</li>
              <li>Flag in sector indicator</li>
              <li>Real-time gaps during races</li>
            </ul>
          </li>
          <li>
            Integration with NDR Race Control App for immediate race control
            updates
          </li>
          <li>And more...</li>
        </ul>
        <p>
          We are actively developing the following features for our website to
          compliment our broadcasts and commentary:
        </p>
        <ul>
          <li>Live timing and session results (to replace LFSW pubstats)</li>
          <li>Real-time track map (to replace LFS Remote)</li>
          <li>
            Driver and team data management portal: Display your driver and team
            data (incl. logo) on stream
          </li>
        </ul>
        <a name="suggestions" href="#suggestions">
          <h2>Make a Suggestion</h2>
        </a>
        <p>
          If you have any feature suggestions, we would be interested to hear
          from you. Before making your suggestion for our Live for Speed
          broadcast overlays please consider the feasibility of what you are
          asking by referring to{" "}
          <a href="https://en.lfsmanual.net/wiki/InSim.txt">InSim.txt</a>. If
          you are unsure, please get in touch anyway.
        </p>
      </TextPane>
      <RequestBookingBanner />
    </Main>
  );
}

export default Technology;
