import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Main, BroadcastStatusBar, RequestBookingBanner } from "./components";
import {
  PositionsList,
  PositionEntry,
  PositionName,
  PositionDescription,
  PositionApply,
} from "./components/Positions";
import { Helmet } from "react-helmet";

const Hero = styled.div`
  width: 100%;
  height: 100px;
  margin-bottom: 25px;
  background: linear-gradient(to bottom left, #2e3f67, #0d1938);
  /* background-color: #2e3f67; */
  color: #ffffff;
  text-align: center;
  height: 100px;
  line-height: 100px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 2.5em;
  overflow: hidden;

  h1 {
    background: linear-gradient(to bottom, #ffffff 0%, #ffffff 30%, #aaaaaa);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0px;
    font-size: inherit;
  }

  @media (max-width: 500px) {
    font-size: 2em;
    height: 75px;
    line-height: 75px;
    margin-bottom: 10px;
  }
`;

const TextPane = styled.article`
  padding: 25px;

  a {
    color: #333;

    &[name] {
      text-decoration: none;
      display: block;

      &:not(:first-child) {
        margin-top: 50px;
      }
    }

    h2 {
      position: relative;
      &::before {
        position: absolute;
        left: -1.5em;
        padding-right: 1.5em;
        line-height: 1.6em;
        font-size: 0.7em;
        font-family: "Font Awesome 5 Pro";
        content: "\uf0c1";
        color: #bbb;
        opacity: 0;
        user-select: none;
        cursor: normal;
      }

      &:hover::before {
        opacity: 1;
      }
    }

    @media (prefers-color-scheme: dark) {
      color: #ddd;
    }
  }

  h2 {
    text-transform: uppercase;
    margin-top: 50px;

    &:first-child {
      margin-top: 0px;
    }

    @media (prefers-color-scheme: dark) {
      color: #fff;
    }
  }

  h3 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0px;
    margin-bottom: 5px;
    font-style: italic;
    color: #555;

    & + p {
      margin-top: 5px;
      margin-bottom: 0px;
    }
  }

  i {
    margin-right: 5px;
    color: #555;

    @media (prefers-color-scheme: dark) {
      color: #fff;
    }
  }

  p,
  li {
    line-height: 1.5em;
  }

  @media (prefers-color-scheme: dark) {
    color: #fff;
  }
`;

function Join() {
  return (
    <Main>
      <Helmet>
        <title>Sim Broadcasts | Join Our Team</title>
      </Helmet>
      <BroadcastStatusBar />
      <Hero>
        <h1>Join Our Team</h1>
      </Hero>
      <TextPane>
        <a name="mission" href="#mission">
          <h2>Mission Statement</h2>
        </a>
        <p>
          Here at Sim Broadcasts, our goal is to provide high quality sim racing
          coverage for event organisers and their audiences. We are a small{" "}
          <Link to="/about#team">team</Link> of passionate racing fans and we
          pride ourselves on going the extra mile to deliver high quality
          productions, fit for our clients' needs.
        </p>
        <a name="positions" href="#positions">
          <h2>Open Positions</h2>
        </a>
        <p>
          If you think you'd be a good fit for us, we are looking to grow our
          team, and have a number of open positions:
        </p>
        <PositionsList>
          <PositionEntry>
            <PositionName>
              <i className="fas fa-microphone"></i> LFS Commentator (English)
            </PositionName>
            <PositionDescription>
              English language commentary position for projects in{" "}
              <a href="https://lfs.net">Live for Speed</a>.
            </PositionDescription>
            <PositionApply to="/join/lfs-commentator-en">
              Apply now
            </PositionApply>
          </PositionEntry>
          <PositionEntry>
            <PositionName>
              <i className="fas fa-video"></i> LFS Videographer
            </PositionName>
            <PositionDescription>
              Videography position for projects in{" "}
              <a href="https://lfs.net">Live for Speed</a>.
            </PositionDescription>
            <PositionApply to="/join/lfs-videographer">Apply now</PositionApply>
          </PositionEntry>
          {/* <PositionEntry>
            <PositionName>
              <i className="fas fa-microphone"></i> LFS Commentator (Spanish)
            </PositionName>
            <PositionDescription>
              Spanish language commentary position for public and private
              projects in <a href="https://lfs.net">Live for Speed</a>.
            </PositionDescription>
            <PositionApply to="/join/lfs-commentator-es">
              Apply now
            </PositionApply>
          </PositionEntry> */}
        </PositionsList>
      </TextPane>
      <RequestBookingBanner />
    </Main>
  );
}

export default Join;
