import React, { useState } from "react";
import styled from "styled-components";
import { Main, BroadcastStatusBar } from "./components";
import { Helmet } from "react-helmet";

const Hero = styled.div`
  width: 100%;
  height: 100px;
  margin-bottom: 25px;
  background: linear-gradient(to bottom left, #2e3f67, #0d1938);
  /* background-color: #2e3f67; */
  color: #ffffff;
  text-align: center;
  height: 100px;
  line-height: 100px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 2.5em;
  overflow: hidden;

  h1 {
    background: linear-gradient(to bottom, #ffffff 0%, #ffffff 30%, #aaaaaa);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0px;
    font-size: inherit;
  }

  @media (max-width: 500px) {
    font-size: 2em;
    height: 75px;
    line-height: 75px;
    margin-bottom: 10px;
  }
`;

const TextPane = styled.article`
  padding: 25px;

  a {
    color: #333;

    &[name] {
      text-decoration: none;
      display: block;

      &:not(:first-child) {
        margin-top: 50px;
      }
    }

    h2 {
      position: relative;
      &::before {
        position: absolute;
        left: -1.5em;
        padding-right: 1.5em;
        line-height: 1.6em;
        font-size: 0.7em;
        font-family: "Font Awesome 5 Pro";
        content: "\uf0c1";
        color: #bbb;
        opacity: 0;
        user-select: none;
        cursor: normal;
      }

      &:hover::before {
        opacity: 1;
      }

      @media (prefers-color-scheme: dark) {
        color: #fff;
      }
    }

    @media (prefers-color-scheme: dark) {
      color: #ddd;
    }
  }

  form {
    * {
      box-sizing: border-box;
    }
    input,
    label,
    select,
    textarea {
      display: block;
      width: 100%;
    }

    input {
      &[type="radio"] {
        height: 15px;
        width: 15px;
        display: none;

        & + label {
          text-align: center;
          padding: 10px;
          height: 70px;
          background-color: #efefef;
          cursor: pointer;
          position: relative;
          margin-bottom: 0px;
          opacity: 1;
          transition: opacity 0.2s ease-in-out;

          &:hover {
            opacity: 0.75;
          }

          &::after {
            position: absolute;
            bottom: 10px;
            left: calc(50% - 50px);
            width: 100px;
            font-weight: bold;
            text-transform: uppercase;
            content: "Choose";
          }

          @media (max-width: 700px) {
            ${"" /* font-size: 0.8em; */}
          }

          @media (prefers-color-scheme: dark) {
            background-color: #333;
          }
        }

        &:checked + label {
          background-color: #0082ff;
          color: #fff;
          &::after {
            position: absolute;
            bottom: 10px;
            left: calc(50% - 50px);
            width: 100px;
            font-weight: bold;
            text-transform: uppercase;
            content: "Selected";
          }
        }
      }
      &[type="text"],
      &[type="email"],
      &[type="url"] {
        height: 40px;
        padding: 8px;
        font-size: 1em;
        border: solid 1px #dfdfdf;

        @media (prefers-color-scheme: dark) {
          border-color: #444;
        }
      }

      &[type="submit"] {
        height: 40px;
        padding: 8px;
        font-size: 1em;
        border: none;
        background-color: #0082ff;
        color: #fff;
        font-weight: 700;
        text-transform: uppercase;
        cursor: pointer;
        opacity: 1;
        transition: opacity 0.2s ease-in-out;

        &:hover {
          opacity: 0.75;
        }
      }

      @media (prefers-color-scheme: dark) {
        background-color: #222;
        color: #fff;
      }
    }

    select:not([multiple]) {
      appearance: none;
      background-position: right 50%;
      background-repeat: no-repeat;
      background-image: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="utf-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" version="1"><path d="M4 8L0 4h8z"/></svg>');
      padding: 0.5em;
      padding-right: 1.5em;
      border-radius: 0px;
      height: 40px;
      font-size: 1em;
      background-color: transparent;
      border-color: #dfdfdf;

      &:disabled {
        border-color: #dfdfdf;
      }

      @media (prefers-color-scheme: dark) {
        color: #fff;
        border-color: #444;
      }
    }

    h3 {
      margin-top: 40px;
      margin-bottom: 15px;
      padding-top: 20px;
      border-top: solid 1px #dfdfdf;

      @media (prefers-color-scheme: dark) {
        border-color: #444;
      }

      &:first-child {
        margin-top: 0px;
        border: none;
      }
    }

    textarea {
      border-color: #dfdfdf;
      min-height: 100px;
      padding: 8px;
      font-size: 1em;

      @media (prefers-color-scheme: dark) {
        background-color: #222;
        border-color: #444;
        color: #fff;
      }
    }

    label {
      margin-bottom: 5px;
    }

    label + p {
      margin: 0px;
      font-size: 0.9em;
      color: #555;
      margin-bottom: 5px;

      @media (prefers-color-scheme: dark) {
        color: #ddd;
      }
    }
  }

  h2 {
    text-transform: uppercase;
    margin-top: 50px;

    &:first-child {
      margin-top: 0px;
    }
  }

  h3 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0px;
    margin-bottom: 5px;
    font-style: italic;
    color: #555;

    & + p {
      margin-top: 5px;
      margin-bottom: 0px;
    }
  }

  i {
    margin-right: 5px;
    color: #555;
  }

  p,
  li {
    line-height: 1.5em;
  }

  @media (prefers-color-scheme: dark) {
    color: #fff;
  }
`;

const Form = styled.form`
  margin-top: 30px;
`;

const FormItem = styled.div`
  flex: 1;
  margin: 0px 10px;

  &:first-child {
    margin-left: 0px;
  }

  &:last-child {
    margin-right: 0px;
  }

  @media (max-width: 500px) {
    margin: 0px 0px 30px 0px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

const FormRow = styled.div`
  display: flex;
  margin-bottom: 30px;

  @media (max-width: 700px) {
    &.radios div:nth-child(2) {
      margin: 0px;
    }
  }

  @media (max-width: 650px) {
    &.radios {
      flex-direction: column;

      & div:nth-child(2) {
        margin: 0px 0px 10px 0px;
      }

      & div {
        margin: 0px 0px 10px 0px;
      }
    }
  }

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

function LFSCommentatorEn() {
  const [sent, setSent] = useState(false);
  const [sending, setSending] = useState(false);

  function sendData(e) {
    e.stopPropagation();
    e.preventDefault();
    e.persist();

    const data = Object.fromEntries(new FormData(e.target).entries());

    fetch("https://live.simbroadcasts.tv/api/v1/applicationmailer", {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ type: "applylfscommen", data }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        setSending(false);
        setSent(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    setSending(true);
  }

  return (
    <Main>
      <Helmet>
        <title>Sim Broadcasts | LFS Commentator (English)</title>
      </Helmet>
      <BroadcastStatusBar />
      <Hero>
        <h1>LFS Commentator (English)</h1>
      </Hero>
      {!sent ? (
        <TextPane>
          <a name="position" href="#position">
            <h2>The Position</h2>
          </a>
          <p>
            English language commentary position for projects in{" "}
            <a href="https://lfs.net">Live for Speed</a>.
          </p>
          <a name="requirements" href="#requirements">
            <h2>Person Specification</h2>
          </a>
          <h3>Required</h3>
          <ul>
            <li>A high level of spoken English.</li>
            <li>A good quality microphone.</li>
            <li>A stable internet connection.</li>
            <li>
              Access to <a href="https://teamspeak.com">Teamspeak</a>,{" "}
              <a href="https://discord.gg">Discord</a> and{" "}
              <a href="https://obsproject.com/">OBS Studio</a>
            </li>
          </ul>
          <h3>Desirable</h3>
          <ul>
            <li>
              An{" "}
              <a href="https://www.lfs.net/shop/licenseselect">
                LFS S3 License
              </a>
              .
            </li>
            <li>Previous motorsport/sim racing commentary experience.</li>
          </ul>
          <a name="responsibilities" href="#responsibilities">
            <h2>Responsibilities</h2>
          </a>
          <ul>
            <li>
              Sim Broadcasts commentators are responsible for calling the action
              on track from the broadcast feed, commentary liaison, and race
              control, as well as presenting any messages from sponsors,
              partners or otherwise.
            </li>
            <li>
              You must ensure you have all relevant software up to date,
              including the SBTV Broadcaster app.
            </li>
            <li>
              You should work with other commentators and series organisers to
              produce and update series prep-sheets before each event.
            </li>
            <li>
              You should be ready for an event on our Teamspeak server 15
              minutes before your broadcast is due to air.
            </li>
            <li>
              You must give a minimum of 2 days notice if you are unable to
              fulfil your commentary role for an event you agreed to cover.
            </li>
          </ul>
          <a name="benefits" href="#benefits">
            <h2>Reasons to apply</h2>
          </a>
          <p>
            You should apply for a commentary role with Sim Broadcasts if you
            are passionate about racing/sim racing and feel as though your
            skills could benefit our service, and the coverage of LFS events for
            our clients. We may have opportunities for paid work, though this is
            not always guaranteed. Any paid work that becomes available to you
            will be offered at a contracted hourly rate and paid upon series
            conclusion.
          </p>
          <a name="apply" href="#apply">
            <h2>Application Process</h2>
          </a>
          <p>
            To apply for this role, we require a few basic details from you, as
            well as a brief description of any relevant past experience you
            have, and what you can bring to the team. If you do not have any
            relevant past experience to show, we have produced a short{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://drive.google.com/file/d/1v9MhC7j4RYzJs2lX4ivkY-Q-D_gp4yZd/view"
            >
              demo broadcast clip
            </a>{" "}
            that you can record commentary over, upload, and send to us.
          </p>
          <Form onSubmit={!sending ? sendData : null}>
            <h3>Step 1: Basic Information</h3>
            <FormRow>
              <FormItem>
                <label htmlFor="forename">Forename</label>
                <input
                  type="text"
                  id="forename"
                  name="forename"
                  placeholder="John"
                  required
                />
              </FormItem>
              <FormItem>
                <label htmlFor="surname">Surname</label>
                <input
                  type="text"
                  id="surname"
                  name="surname"
                  placeholder="Smith"
                  required
                />
              </FormItem>
            </FormRow>
            <FormRow>
              <FormItem>
                <label htmlFor="email">Email Address</label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="john.smith@example.com"
                  required
                />
              </FormItem>
              <FormItem>
                <label htmlFor="lfsusername">LFS Username (optional)</label>
                <input
                  id="lfsusername"
                  name="lfsusername"
                  type="text"
                  placeholder="johnsmith"
                />
              </FormItem>
            </FormRow>
            <h3>Step 2: Relevant Experience and Reasons for Applying</h3>
            <FormRow>
              <FormItem>
                <label htmlFor="experience">
                  Previous Commentary Experience
                </label>
                <p>
                  Please outline any previous commentary experience you have,
                  especially if you have any previous racing or sim racing
                  commentary experience. If you do not have previous experience,
                  please download{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://drive.google.com/file/d/1v9MhC7j4RYzJs2lX4ivkY-Q-D_gp4yZd/view"
                  >
                    this broadcast demo
                  </a>{" "}
                  and record some commentary over it with OBS Studio (or
                  equivalent). Then, upload it to either YouTube or some other
                  cloud storage solution, and share the link with us below.
                </p>
                <textarea
                  id="experience"
                  name="experience"
                  placeholder=""
                  required
                />
              </FormItem>
            </FormRow>
            <FormRow>
              <FormItem>
                <label htmlFor="reasons">Reasons for Applying</label>
                <p>
                  Please tell us why you are applying for this role, and
                  specifically what you think you can bring to the team.
                </p>
                <textarea id="reasons" name="reasons" placeholder="" required />
              </FormItem>
            </FormRow>
            {!sent ? (
              <input
                type="submit"
                value={!sending ? "Submit Application" : "Submitting..."}
              />
            ) : null}
          </Form>
        </TextPane>
      ) : (
        <TextPane>
          <h2>Thank You!</h2>
          <h3>Your application has been sent.</h3>
          <p>
            If you do not hear back from one of our team within 2 business days
            please <a href="mailto:contact@simbroadcasts.tv">contact us</a>.
          </p>
        </TextPane>
      )}
    </Main>
  );
}

export default LFSCommentatorEn;
