import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
// import hero from "../../assets/ads/request-booking.jpg";

const Ad = styled.div`
  /* background-color: #293242; */
  background: linear-gradient(to bottom left, #2e3f67, #0d1938);
  height: 125px;
  display: flex;
  align-items: center;
  margin-top: 25px;
  overflow: hidden;

  @media (max-width: 700px) {
    flex-direction: column;
    height: auto;
    padding-bottom: 25px;
  }

  @media (max-width: 500px) {
    margin-top: 10px;
  }
`;

// const RequestBookingAd = styled.div`
//   background-image: url(${hero});
//   background-size: 100%;
//   background-repeat: no-repeat;
//   background-position: center;
//   flex: 1;
//   height: 150px;

//   @media (max-width: 700px) {
//     width: 100%;
//     height: 100px;
//     flex: none;
//   }
// `;

const RequestBookingAd2 = styled.div`
  flex: 1;
  /* height: 150px; */
  font-size: 1.2em;
  padding: 25px;
  text-align: center;
  align-items: center;
  color: white;
  font-weight: 700;
  text-transform: uppercase;

  @media (max-width: 700px) {
    font-size: 1em;
    width: auto;
    flex: none;
  }
`;

const RequestBookingButton = styled(Link)(
  ({ calltoaction }) => css`
    font-size: 1em;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;
    padding: 8px 12px;
    border: ${calltoaction ? "solid 3px #fff" : "none"};
    margin: 0px 50px 0px 10px;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }

    @media (max-width: 700px) {
      margin: 0;
    }
  `
);

function RequestBookingBanner() {
  return (
    <Ad>
      {/* <RequestBookingAd /> */}
      <RequestBookingAd2>
        <span>Hosting a single race or an entire season?</span>
      </RequestBookingAd2>
      <RequestBookingButton calltoaction="true" to="/bookings">
        Request Your Broadcast
      </RequestBookingButton>
    </Ad>
  );
}

export default RequestBookingBanner;
