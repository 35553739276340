import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

const Wrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;

  h1 {
    @media (prefers-color-scheme: dark) {
      color: #fff;
    }
  }

  a {
    color: #333;

    @media (prefers-color-scheme: dark) {
      color: #ddd;
    }
  }

  @media (prefers-color-scheme: dark) {
    color: #fff;
  }
`;

function Vote() {
  const [dotd, setDotd] = useState(null);
  const api = "https://live.simbroadcasts.tv/api/v1/dotd";

  useEffect(() => {
    fetch(api, {
      method: "GET",
    }).then((res) =>
      res.text().then((dotdUrl) => {
        if (dotdUrl) {
          setDotd(dotdUrl);
          window.location.href = dotdUrl;
        }
      })
    );
  }, []);

  return (
    <Wrapper>
      <Helmet>
        <title>Sim Broadcasts | Vote</title>
      </Helmet>
      {dotd ? (
        <p>
          If this page does not automatically redirect, please{" "}
          <a href={dotd}>click here</a>.
        </p>
      ) : (
        <p>Redirecting...</p>
      )}
    </Wrapper>
  );
}

export default Vote;
