import React from "react";
import styled from "styled-components";

const StyledFooter = styled.footer`
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;

  @media (max-width: 500px) {
    margin-top: 10px;
    padding: 0px 15px;
    flex-flow: row wrap;
  }
`;

const CopyrightNotice = styled.span`
  color: #000;

  @media (max-width: 500px) {
    text-align: center;
    flex: 0 0 100%;
    padding: 15px 0px 10px 0px;
  }

  @media (prefers-color-scheme: dark) {
    color: #fff;
  }
`;

const FooterNav = styled.nav`
  @media (max-width: 500px) {
    text-align: center;
    flex: 0 0 100%;
    padding: 10px 0px 25px 0px;
  }
`;

const StyledFooterNavLink = styled.a`
  text-decoration: none;
  color: #000;
  margin-right: 15px;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.5;
  }

  &:last-child {
    margin-right: 0px;
  }

  @media (prefers-color-scheme: dark) {
    color: #fff;
  }
`;

const FooterNavLink = (props) => (
  <StyledFooterNavLink href={props.url}>
    {props.children ? props.children : <i className={props.icon}></i>}
  </StyledFooterNavLink>
);

const Footer = (props) => {
  let year = new Date(Date.now()).getFullYear();
  return (
    <StyledFooter>
      <CopyrightNotice>&copy; {year} Sim Broadcasts</CopyrightNotice>
      <FooterNav>
        <FooterNavLink
          url="https://twitch.tv/simbroadcasts"
          icon="fab fa-twitch"
        />
        <FooterNavLink
          url="https://www.youtube.com/simbroadcasts"
          icon="fab fa-youtube"
        />
        <FooterNavLink
          url="https://simbroadcasts.tv/voice"
          icon="far fa-user-headset"
        />
        <FooterNavLink
          url="https://facebook.com/simbroadcasts"
          icon="fab fa-facebook-square"
        />
        <FooterNavLink
          url="https://twitter.com/simbroadcasts"
          // icon="fab fa-twitter"
        >
          <strong style={{ "font-size": "1.2em" }}>𝕏</strong>
        </FooterNavLink>
        <FooterNavLink
          url="https://m.me/SimBroadcasts"
          icon="fab fa-facebook-messenger"
        />
        <FooterNavLink
          url="https://github.com/simbroadcasts"
          icon="fab fa-github"
        />
        <FooterNavLink
          url="mailto:contact@simbroadcasts.tv"
          icon="far fa-envelope"
        />
      </FooterNav>
    </StyledFooter>
  );
};

export default Footer;
