import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { Drawer } from "./";
import LogoBlack from "../../assets/logo/sim-broadcasts-logo-t-b.png";
import LogoWhite from "../../assets/logo/sim-broadcasts-logo-t-w.png";

const StyledHeader = styled.header`
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;

  @media (max-width: 500px) {
    margin-bottom: 10px;
  }
`;

const Logo = styled(Link)`
  background-image: url(${LogoBlack});
  width: 250px;
  height: 30px;
  background-size: 100%;
  background-repeat: no-repeat;
  transition: filter 0.2s ease-in-out;

  &:hover {
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.4));
  }

  @media (prefers-color-scheme: dark) {
    background-image: url(${LogoWhite});
  }
`;

const Nav = styled.nav`
  height: 30px;
  display: flex;
  align-items: center;

  @media (max-width: 900px) {
    display: none;
  }
`;

const NavItem = styled(Link)(
  ({ calltoaction }) => css`
    font-size: 1em;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    color: #000000;
    padding: 8px 12px;
    border: ${calltoaction ? "solid 3px #000000" : "none"};
    margin-right: 0px;
    margin-left: ${calltoaction ? "8px" : "0px"};
    transition: background-color 0.2s ease-in-out;
    border-radius: ${calltoaction ? "0px" : "4px"};

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

    &:last-child {
      margin-right: 0px;
      margin-left: 8px;
    }

    @media (prefers-color-scheme: dark) {
      color: #ffffff;
      border: ${calltoaction ? "solid 3px #ffffff" : "none"};

      &:hover {
        background-color: rgba(255, 255, 255, 0.05);
      }
    }
  `
);

const Obfuscator = styled.a`
  display: block;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 2;
  transition: opacity 0.2s ease-in-out;
  background-color: rgba(0, 0, 0, 0.75);
  pointer-events: none;
  opacity: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &.active {
    opacity: 1;
    pointer-events: auto;
  }

  &:hover {
    cursor: pointer;
  }
`;

const ToggleDrawer = styled.a`
  display: none;
  height: 40px;
  width: 40px;
  color: #000;
  float: left;
  line-height: 40px;
  text-align: center;
  border-radius: 4px;
  font-size: 1.5em;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  @media (max-width: 900px) {
    display: block;
  }

  @media (prefers-color-scheme: dark) {
    color: #ffffff;

    &:hover {
      background-color: rgba(255, 255, 255, 0.05);
    }
  }
`;

const ToggleDrawerV = styled.a`
  display: block;
  height: 40px;
  width: 40px;
  color: #000;
  float: left;
  line-height: 40px;
  text-align: center;
  border-radius: 4px;
  font-size: 1.5em;
  cursor: pointer;
  margin-left: 8px;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  @media (max-width: 900px) {
    display: none;
  }

  @media (prefers-color-scheme: dark) {
    color: #ffffff;

    &:hover {
      background-color: rgba(255, 255, 255, 0.05);
    }
  }
`;

const Header = (props) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    drawerOpen
      ? document.body.classList.add("no-scroll")
      : document.body.classList.remove("no-scroll");
  }, [drawerOpen]);

  return (
    <StyledHeader>
      <Logo to="/" />
      <Nav>
        <NavItem to="/">Home</NavItem>
        <NavItem to="/about">About Us</NavItem>
        <NavItem to="/calendar">Calendar</NavItem>
        <NavItem calltoaction="true" to="/bookings">
          Request Your Broadcast
        </NavItem>
        <ToggleDrawerV onClick={toggleDrawer}>
          <i className="far fa-ellipsis-v" />
        </ToggleDrawerV>
      </Nav>
      <ToggleDrawer onClick={toggleDrawer}>
        <i className="far fa-bars" />
      </ToggleDrawer>
      <Drawer
        drawerOpen={drawerOpen}
        actions={{
          toggleDrawer,
        }}
      />
      <Obfuscator
        className={drawerOpen ? "active" : null}
        onClick={toggleDrawer}
      />
    </StyledHeader>
  );
};

export default Header;
