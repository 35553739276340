import React from "react";
import styled, { css } from "styled-components";
import {
  Main,
  BroadcastStatusBar,
  RequestBookingBanner,
  Table,
} from "./components";
import calendar from "../calendar.json";
import { Helmet } from "react-helmet";

const Hero = styled.div`
  width: 100%;
  height: 100px;
  margin-bottom: 25px;
  background: linear-gradient(to bottom left, #2e3f67, #0d1938);
  /* background-color: #2e3f67; */
  color: #ffffff;
  text-align: center;
  height: 100px;
  line-height: 100px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 2.5em;
  overflow: hidden;

  h1 {
    background: linear-gradient(to bottom, #ffffff 0%, #ffffff 30%, #aaaaaa);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0px;
    font-size: inherit;
  }

  @media (max-width: 550px) {
    font-size: 2.2em;
  }

  @media (max-width: 500px) {
    font-size: 2em;
    height: 75px;
    line-height: 75px;
    margin-bottom: 10px;
  }

  @media (max-width: 400px) {
    font-size: 1.7em;
    height: 60px;
    line-height: 60px;
  }
`;

const TextPane = styled.article`
  padding: 25px;

  a {
    color: #333;

    &[name] {
      text-decoration: none;
      display: block;

      &:not(:first-child) {
        margin-top: 50px;
      }
    }

    h2 {
      position: relative;
      &::before {
        position: absolute;
        left: -1.5em;
        padding-right: 1.5em;
        line-height: 1.6em;
        font-size: 0.7em;
        font-family: "Font Awesome 5 Pro";
        content: "\uf0c1";
        color: #bbb;
        opacity: 0;
        user-select: none;
        cursor: normal;
      }

      &:hover::before {
        opacity: 1;
      }
    }

    @media (prefers-color-scheme: dark) {
      color: #ddd;
    }
  }

  h2 {
    text-transform: uppercase;
    margin-top: 50px;

    &:first-child {
      margin-top: 0px;
    }

    @media (prefers-color-scheme: dark) {
      color: #fff;
    }
  }

  h3 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0px;
    margin-bottom: 5px;
    font-style: italic;
    color: #555;

    & + p {
      margin-top: 5px;
      margin-bottom: 0px;
    }
  }

  i {
    margin-right: 5px;
    /* color: #555; */

    /* @media (prefers-color-scheme: dark) { */
    color: #fff;
    /* } */
  }

  p,
  li {
    line-height: 1.5em;
  }

  @media (prefers-color-scheme: dark) {
    color: #fff;
  }

  .stats {
    font-size: 0.9em;
    strong {
      display: inline-block;
      width: 180px;
    }
  }
`;

const ScrollingTable = styled.div`
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  font-size: 0.9em;
  padding: 15px;

  a {
    color: #333;

    @media (prefers-color-scheme: dark) {
      color: #fff;
    }
  }

  @media (prefers-color-scheme: dark) {
    color: #fff;
  }
`;

const MiniSeriesLogo = styled.div(({ event }) => {
  let series = calendar.series.find((el) => el.name === event.series);

  return css`
    width: 30px;
    height: 30px;
    background-image: url(../assets/series-icons/${series.icon});
    background-repeat: no-repeat;
    background-size: 30px;
    background-position: center;
    padding: 4px;

    @media (prefers-color-scheme: light) {
      background-color: #2e3f67;
      border-radius: 4px;
    }
  `;
});

function CalendarArchive() {
  window.calendar = calendar;
  let archive = [];

  [...calendar.events].reverse().forEach((el) => {
    if (el.date_uts < Date.now() / 1000) {
      archive.push(el);
    }
  });

  const seriesCount = calendar.series.length;
  const eventCount = archive.length;
  const seriesOrganisersCount = new Set([
    ...calendar.series.map((el) => el.organiser),
  ]).size;

  function timeSince(to) {
    const ms = Date.now() - to * 1000;
    const years = ms / 1000 / 60 / 60 / 24 / 365;
    const fullYears = Math.floor(years);
    const dY = years - fullYears;
    const months = dY / (1 / 12);
    const fullMonths = Math.floor(months);
    const dM = months - fullMonths;
    const days = dM / (1 / 30);
    const fullDays = Math.floor(days);
    return [fullYears, fullMonths, fullDays];
  }

  // First broadcast
  const [fullYears, fullMonths, fullDays] = timeSince(
    calendar.events[0].date_uts
  );

  // Founded (22nd Feb 2020)
  const [sFullYears, sFullMonths, sFullDays] = timeSince(1582329600);

  // Hours broadcast
  let hoursBroadcast = calendar.events
    .map((el) => {
      let times = el.date_string.split(" ")[3].split("-");
      let tStart = new Date();
      let tEnd = new Date();
      let vStart = times[0].split(":");
      let vEnd = times[1].split(":");

      tStart.setHours(vStart[0], vStart[1], 0, 0);
      tEnd.setHours(vEnd[0], vEnd[1], 0, 0);

      let fudge = 0;

      // hours
      return (tEnd - tStart) / 1000 / 60 / 60 + fudge;
    })
    .reduce((a, b) => a + b);

  // Days on air
  let daysOnAir = hoursBroadcast / 24;

  return (
    <Main>
      <Helmet>
        <title>Sim Broadcasts | Broadcast Archive</title>
      </Helmet>
      <BroadcastStatusBar />
      <Hero>
        <h1>Broadcast Archive</h1>
      </Hero>
      <TextPane>
        <a name="mission" href="#mission">
          <h2>Public Broadcast Archive</h2>
        </a>
        <p>
          This page contains a list of past broadcasts from our public projects.
          We also maintain an{" "}
          <a href="https://drive.google.com/drive/folders/1iEktO77bUY_jYOQRwPdQPYKZK72lUmam?usp=sharing">
            LFS Replay Repository
          </a>{" "}
          which contains replays of every series we have broadcast since
          February 16th 2021.
        </p>
        <p className="stats">
          <strong>Broadcasts to date: </strong> {eventCount}
          <br></br>
          <strong>Unique series: </strong> {seriesCount}
          <br></br>
          <strong>Unique organisers: </strong> {seriesOrganisersCount}
          <br></br>
          <strong>First broadcast aired: </strong> {fullYears} years,{" "}
          {fullMonths} {fullMonths === 1 ? "month" : "months"}, {fullDays}{" "}
          {fullDays === 1 ? "day" : "days"} ago
          <br></br>
          <strong>Sim Broadcasts founded: </strong> {sFullYears} years,{" "}
          {sFullMonths} {sFullMonths === 1 ? "month" : "months"}, {sFullDays}{" "}
          {sFullDays === 1 ? "day" : "days"} ago
          <br></br>
          <strong>Estimated hours on air: </strong> ~
          {Math.floor(hoursBroadcast)} hours ({Math.floor(daysOnAir)}{" "}
          {daysOnAir === 1 ? "day" : "days"})
        </p>
      </TextPane>
      <ScrollingTable>
        <Table>
          <thead>
            <tr>
              <th>Date</th>
              <th></th>
              <th>Series</th>
              {/* <th>Event</th> */}
              {/* <th>Track</th> */}
              {/* <th>Car Class</th> */}
              <th>VOD</th>
              {/* <th>Name</th> */}
              {/* <th>Track</th> */}
              {/* <th>Car</th> */}
            </tr>
          </thead>
          <tbody>
            {archive.map((el, i) => {
              const series = calendar.series.find(
                (el2) => el2.name === el.series
              );
              return (
                <tr key={i}>
                  <td>{new Date(el.date_uts * 1000).toLocaleDateString()}</td>
                  <td>
                    <MiniSeriesLogo event={el} />
                  </td>
                  <td>
                    <strong>
                      <a href={series.website}>
                        {series.organiser} {series.series}
                      </a>
                    </strong>{" "}
                    - {el.name}
                    <br />
                    <em>
                      {el.car.toUpperCase()} @ {el.track}
                    </em>
                  </td>
                  {/* <td></td> */}
                  {/* <td></td> */}
                  {/* <td>{el.car.toUpperCase()}</td> */}
                  <td>
                    {el.vod && (
                      <a
                        href={el.vod}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-youtube"></i>
                      </a>
                    )}{" "}
                    {el?.highlights ? (
                      <a href={el.highlights}>
                        <i className="far fa-film"></i>
                      </a>
                    ) : null}
                  </td>
                  {/* <td>{el.name}</td> */}
                  {/* <td>{el.track}</td> */}
                  {/* <td>{el.car.toUpperCase()}</td> */}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </ScrollingTable>
      <RequestBookingBanner />
    </Main>
  );
}

export default CalendarArchive;
