import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import {
  Main,
  BroadcastStatusBar,
  Event,
  RequestBookingBanner,
  Channels,
} from "./components";
import { Link } from "react-router-dom";
import hero from "../assets/hero/2024-website-hero.png";
import calendar from "../calendar.json";
import { Helmet } from "react-helmet";
import BackgroundVideo from "../assets/video/sbtv_website_banner_30fps_0_72mbit.mp4";

const Hero = styled(Link)`
  display: block;
  /* background-image: url(${hero}); */
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* margin-bottom: 25px; */
  background: linear-gradient(to bottom left, #2e3f67, #0d1938);
  background-color: #2e3f67;
  /* background: linear-gradient() */
  overflow: hidden;
  position: relative;

  img {
    position: absolute;
    width: 100%;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    pointer-events: none;
  }

  video {
    position: absolute;
    z-index: 0;
    object-fit: cover;
    object-position: 50% 50%;
    width: 100%;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  @media (max-width: 700px) {
    height: 175px;
  }

  @media (max-width: 500px) {
    height: 150px;
    /* margin-bottom: 10px; */
  }
`;

function Home() {
  const upcoming = [];

  const videoRef = useRef(null);

  calendar.events.forEach((el) => {
    if (el.date_uts > Date.now() / 1000) {
      // if (simFilter !== 'all') {
      //   if (el.series)
      // }
      upcoming.push(el);
    }
  });

  // Replaces video loop to avoid black frame
  useEffect(() => {
    const handleEnded = () => {
      videoRef.current.currentTime = 0.034; // 1/30 = 0.0333...
      videoRef.current.play();
    };

    videoRef.current.addEventListener("ended", handleEnded);

    const v = videoRef.current;

    return () => {
      v.removeEventListener("ended", handleEnded);
    };
  }, []);

  return (
    <Main>
      <Helmet>
        <title>Sim Broadcasts</title>
      </Helmet>
      <BroadcastStatusBar />
      <Hero>
        <img src={hero} alt="hero" />
        <video
          id="background-video"
          ref={videoRef}
          autoPlay
          playsInline
          muted
          // poster={hero}
          // width="100%"
        >
          <source src={BackgroundVideo} type="video/mp4" />
        </video>
      </Hero>
      <Channels />
      <Event event={upcoming[0]} id={0} cal />
      <RequestBookingBanner />
    </Main>
  );
}

export default Home;
