import styled from "styled-components";
import { Link } from "react-router-dom";

export const PositionsList = styled.ul`
  padding: 0px;
  margin: 0px;
  list-style-type: none;
  display: flex;
  justify-content: space-between;

  @media (max-width: 750px) {
    flex-flow: row wrap;
  }
`;

export const PositionEntry = styled.li`
  box-sizing: border-box;
  width: 49%;
  padding: 20px;
  border: solid 1px #dfdfdf;

  @media (max-width: 750px) {
    width: 100%;
    margin-bottom: 20px;
  }

  @media (prefers-color-scheme: dark) {
    border-color: #444;
  }
`;

export const PositionName = styled.h3``;

export const PositionDescription = styled.p``;

export const PositionApply = styled(Link)`
  height: 40px;
  padding: 8px 12px;
  font-size: 1em;
  border: none;
  background-color: #0082ff;
  color: #fff !important;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    opacity: 0.75;
  }
`;

export default {
  PositionsList,
  PositionEntry,
  PositionName,
  PositionDescription,
  PositionApply,
};
