import React from "react";
import styled from "styled-components";
import { Main, BroadcastStatusBar, RequestBookingBanner } from "./components";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Hero = styled.div`
  width: 100%;
  height: 100px;
  margin-bottom: 25px;
  background: linear-gradient(to bottom left, #2e3f67, #0d1938);
  color: #ffffff;
  text-align: center;
  height: 100px;
  line-height: 100px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 2.5em;
  overflow: hidden;

  h1 {
    background: linear-gradient(to bottom, #ffffff 0%, #ffffff 30%, #aaaaaa);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0px;
    font-size: inherit;
  }

  @media (max-width: 500px) {
    font-size: 2em;
    height: 75px;
    line-height: 75px;
    margin-bottom: 10px;
  }
`;

const TextPane = styled.article`
  padding: 25px;

  a {
    color: #333;

    &[name] {
      text-decoration: none;
      display: block;

      &:not(:first-child) {
        margin-top: 50px;
      }
    }

    h2 {
      position: relative;
      &::before {
        position: absolute;
        left: -1.5em;
        padding-right: 1.5em;
        line-height: 1.6em;
        font-size: 0.7em;
        font-family: "Font Awesome 5 Pro";
        content: "\uf0c1";
        color: #bbb;
        opacity: 0;
        user-select: none;
        cursor: normal;
      }

      &:hover::before {
        opacity: 1;
      }
    }

    @media (prefers-color-scheme: dark) {
      color: #ddd;
    }
  }

  h2 {
    text-transform: uppercase;
    margin-top: 50px;

    &:first-child {
      margin-top: 0px;
    }

    @media (prefers-color-scheme: dark) {
      color: #fff;
    }
  }

  h3 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0px;
    margin-bottom: 5px;
    font-style: italic;
    color: #555;

    & + p {
      margin-top: 5px;
      margin-bottom: 0px;
    }
  }

  i {
    margin-right: 5px;
    color: #555;

    @media (prefers-color-scheme: dark) {
      color: #fff;
    }
  }

  p,
  li {
    line-height: 1.5em;
  }

  @media (prefers-color-scheme: dark) {
    color: #fff;
  }
`;

function Supporters() {
  return (
    <Main>
      <Helmet>
        <title>Sim Broadcasts | Supporters Club</title>
      </Helmet>
      <BroadcastStatusBar />
      <Hero>
        <h1>Supporters Club</h1>
      </Hero>
      <TextPane>
        <a name="supporters" href="#supporters">
          <h2>A note to our supporters</h2>
        </a>
        <p>
          Just watching our content and enjoying it is enough to show your
          support. We appreciate each and every one of you for giving your time
          to watch the racing action we deliver to you.
        </p>
        <p>
          If however you decide you want to give a little something extra back
          to us as a further show of support, you can subscribe to our{" "}
          <a href="https://youtube.com/simbroadcasts">YouTube</a> channel,
          follow or subscribe to us on{" "}
          <a href="https://twitch.tv/simbroadcasts">Twitch</a>, or pick up
          something from our <Link to="/shop">shop</Link>.
        </p>
        <a name="twitch-subs" href="#twitch-subs">
          <h2>Perks for Twitch Subscribers</h2>
        </a>
        <p>
          If you are subscribed to us on{" "}
          <a href="https://twitch.tv/simbroadcasts">Twitch</a>, you can get
          access to the following perks:
        </p>
        <ul>
          <li>
            A Twitch status badge next to you name when you are on camera during
            our LFS broadcasts.
          </li>
          <li>
            Access to the exclusive Sim Broadcasts Supporters Club Discord
            server
          </li>
          <li>
            Coming soon: Discounted merchandise from our{" "}
            <Link to="/shop">shop</Link>.
          </li>
        </ul>
        <p>
          For instructions on how to claim these perks, see{" "}
          <a href="/supporters#twitch-sub-instructions">
            Claim your Twitch Subscription Perks
          </a>{" "}
          below.
        </p>
        <a name="twitch-sub-instructions" href="#twitch-sub-instructions">
          <h2>Claim your Twitch Subscription Perks</h2>
        </a>
        <p>
          Sim Broadcasts Twitch subscriber perks are managed via our Supporters
          Club Discord server, accessible only to those who are subscribed to us
          on Twitch. To access the server, and your perks, follow these
          instructions:
        </p>
        <ol>
          <li>
            Subscribe to{" "}
            <a href="https://twitch.tv/simbroadcasts">
              Sim Broadcasts on Twitch
            </a>
          </li>
          <li>
            Add your Twitch account to your Discord connections:
            <ol type="i">
              <li>
                In <strong>Discord</strong>, navigate to{" "}
                <strong>User Settings</strong>
              </li>
              <li>
                Choose <strong>Connections</strong> from the left menu
              </li>
              <li>
                Connect your <strong>Twitch</strong> profile to your Discord
                account
              </li>
              <li>
                Once added, you should see a list of{" "}
                <strong>Sub Enabled Channels</strong> under your Twitch profile
              </li>
              <li>
                Click <strong>Join</strong> next to the entry for the{" "}
                <strong>Sim Broadcasts Supporters Club</strong>
              </li>
            </ol>
          </li>
          <li>
            You should now see the{" "}
            <strong>Sim Broadcasts Supporters Club</strong> server in your list
            of Discord servers
          </li>
        </ol>

        <p>
          To display your Twitch status on our LFS broadcasts, follow these
          additional steps:
        </p>

        <ol>
          <li>
            In any of the text channels in the{" "}
            <strong>Sim Broadcasts Supporters Club</strong> server, type:{" "}
            <code>/verify</code>
          </li>
          <li>Follow the prompts to complete the verification process</li>
        </ol>
        <p>
          Once completed, your Twitch username will now be linked to your LFS
          username in our database, allowing us to display your Twitch status on
          our LFS broadcasts.
        </p>
        <p>
          If you wish to unlink you account and remove your Twitch status from
          our LFS broadcasts, please{" "}
          <a href="mailto:contact@simbroadcasts.tv">contact us</a> and we will
          promptly unlink your account. Your other perks will still be
          available, and you can re-link your Twitch status at any time.
        </p>
      </TextPane>
      <RequestBookingBanner />
    </Main>
  );
}

export default Supporters;
